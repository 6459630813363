/* eslint-disable max-len */
// Auto generated file. Do not edit.

export const destination: Record<string, TDestination> = {
  'en-AU': {
    destination: {
      title: 'Explore the world with us',
      description: 'Learn more about the spectacular destinations and attractions that you can experience on an expedition cruise with us',
      destinationsButton: 'Go to destination',
      storiesTitle: 'Stories from',
      associatedVoyagesTitle: 'Cruises to',
      highlights: {
        reasonsTo: 'Reasons to'
      },
      seasonality: {
        title: 'When to see',
        bestTime: 'Best time',
        goodTime: 'Good time',
        noServiceTo: 'No service to',
        from: 'from',
        buttonText: 'Search for best {title} cruises'
      },
      shipsTo: 'Ships to',
      shipsToShortDescription: 'Explore our ships that regularly sail to',
      topExperiencesTitle: 'Top experiences',
      seeAllFaqs: 'See all FAQs',
      secondaryTitle: 'Our destinations',
      activities: {
        footnote: '* Please note that these are optional activities; an additional cost may apply.',
        seeAllActivities: 'See all activities for {name}',
        title: 'Activities in {name}'
      },
      map: {
        button: 'Read our travel guide and FAQs.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'View cruises'
        }
      }
    },
    v2: {
      exploreDestination: 'Explore {DESTINATION}',
      seeDestinationCruises: 'See {DESTINATION} cruises',
      backToTop: 'Back to top',
      seeMoreArticles: 'See more articles',
      latestArticles: 'Latest Articles',
      learnMoreAboutHX: 'Learn more ',
      destinationCruises: '{DESTINATION} cruises',
      chooseCruise: 'Choose a cruise',
      videoUnavailable: 'Video playback is not available',
      showResultCount: 'Showing {number} cruises',
      when: 'When',
      budget: 'What\'s your budget?',
      clear: 'Clear',
      clearAll: 'Clear all',
      search: 'Search',
      min: 'Min',
      max: 'Max',
      multipleOffers: 'Multiple Offers',
      numberDeparturesBetweenDates: '{number} departures between {date1} and {date2}',
      fromPrice: 'From {price}',
      addDate: 'Add date',
      addPrice: 'Add price',
      updateResults: 'Update results',
      imageDescriptionOfMap: 'A map of the destination route',
      arialabel: {
        offersThatApplyToThisTag: 'Offers that apply to this cruise',
        previousYear: 'Previous year',
        nextYear: 'Next year',
        searchCruises: 'Search cruises'
      },
      ourOtherDestinationsTitle: 'Our other destinations'
    }
  },
  'en-GB': {
    destination: {
      title: 'Explore the world with us',
      description: 'Learn more about the spectacular destinations and attractions that you can experience on an expedition cruise with us',
      destinationsButton: 'Go to destination',
      storiesTitle: 'Stories from',
      associatedVoyagesTitle: 'Cruises to',
      highlights: {
        reasonsTo: 'Reasons to'
      },
      seasonality: {
        title: 'When to see',
        bestTime: 'Best time',
        goodTime: 'Good time',
        noServiceTo: 'No service to',
        from: 'from',
        buttonText: 'Search for best {title} cruises'
      },
      shipsTo: 'Ships to',
      shipsToShortDescription: 'Explore our ships that regularly sail to',
      topExperiencesTitle: 'Top experiences',
      seeAllFaqs: 'See all FAQs',
      secondaryTitle: 'Our destinations',
      activities: {
        footnote: '* Please note that these are optional activities; an additional cost may apply.',
        seeAllActivities: 'See all activities for {name}',
        title: 'Activities in {name}'
      },
      map: {
        button: 'Read our travel guide and FAQs.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'View cruises'
        }
      }
    },
    v2: {
      exploreDestination: 'Explore {DESTINATION}',
      seeDestinationCruises: 'See {DESTINATION} cruises',
      backToTop: 'Back to top',
      seeMoreArticles: 'See more articles',
      latestArticles: 'Latest Articles',
      learnMoreAboutHX: 'Learn more ',
      destinationCruises: '{DESTINATION} cruises',
      chooseCruise: 'Choose a cruise',
      videoUnavailable: 'Video playback is not available',
      showResultCount: 'Showing {number} cruises',
      when: 'When',
      budget: 'What\'s your budget?',
      clear: 'Clear',
      clearAll: 'Clear all',
      search: 'Search',
      min: 'Min',
      max: 'Max',
      multipleOffers: 'Multiple Offers',
      numberDeparturesBetweenDates: '{number} departures between {date1} and {date2}',
      fromPrice: 'From {price}',
      addDate: 'Add date',
      addPrice: 'Add price',
      updateResults: 'Update results',
      imageDescriptionOfMap: 'A map of the destination route',
      arialabel: {
        offersThatApplyToThisTag: 'Offers that apply to this cruise',
        previousYear: 'Previous year',
        nextYear: 'Next year',
        searchCruises: 'Search cruises'
      },
      ourOtherDestinationsTitle: 'Our other destinations'
    }
  },
  'en-US': {
    destination: {
      title: 'Explore the world with us',
      description: 'Learn more about the spectacular destinations and attractions that you can experience on an expedition cruise with us',
      destinationsButton: 'Go to destination',
      storiesTitle: 'Stories from',
      associatedVoyagesTitle: 'Cruises to',
      highlights: {
        reasonsTo: 'Reasons to'
      },
      seasonality: {
        title: 'When to see',
        bestTime: 'Best time',
        goodTime: 'Good time',
        noServiceTo: 'No service to',
        from: 'from',
        buttonText: 'Search for best {title} cruises'
      },
      shipsTo: 'Ships to',
      shipsToShortDescription: 'Explore our ships that regularly sail to',
      topExperiencesTitle: 'Top experiences',
      seeAllFaqs: 'See all FAQs',
      secondaryTitle: 'Our destinations',
      activities: {
        footnote: '* Please note that these are optional activities; an additional cost may apply.',
        seeAllActivities: 'See all activities for {name}',
        title: 'Activities in {name}'
      },
      map: {
        button: 'Read our travel guide and FAQs.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'View cruises'
        }
      }
    },
    v2: {
      exploreDestination: 'Explore {DESTINATION}',
      seeDestinationCruises: 'See {DESTINATION} cruises',
      backToTop: 'Back to top',
      seeMoreArticles: 'See more articles',
      latestArticles: 'Latest Articles',
      learnMoreAboutHX: 'Learn more ',
      destinationCruises: '{DESTINATION} cruises',
      chooseCruise: 'Choose a cruise',
      videoUnavailable: 'Video playback is not available',
      showResultCount: 'Showing {number} cruises',
      when: 'When',
      budget: 'What\'s your budget?',
      clear: 'Clear',
      clearAll: 'Clear all',
      search: 'Search',
      min: 'Min',
      max: 'Max',
      multipleOffers: 'Multiple Offers',
      numberDeparturesBetweenDates: '{number} departures between {date1} and {date2}',
      fromPrice: 'From {price}',
      addDate: 'Add date',
      addPrice: 'Add price',
      updateResults: 'Update results',
      imageDescriptionOfMap: 'A map of the destination route',
      arialabel: {
        offersThatApplyToThisTag: 'Offers that apply to this cruise',
        previousYear: 'Previous year',
        nextYear: 'Next year',
        searchCruises: 'Search cruises'
      },
      ourOtherDestinationsTitle: 'Our other destinations'
    }
  },
  'de-DE': {
    destination: {
      title: 'Entdecken Sie die Welt mit uns',
      description: 'Erfahren Sie mehr über die spektakulären Reiseziele und besonderen Erlebnisse, die eine Expeditions-Seereise mit uns unvergesslich machen',
      destinationsButton: 'Weiter zum Reiseziel',
      storiesTitle: 'Geschichten von',
      associatedVoyagesTitle: 'Seereisen nach',
      highlights: {
        reasonsTo: 'Gründe für'
      },
      seasonality: {
        title: 'Die beste Reisezeit',
        bestTime: 'Beste Reisezeit',
        goodTime: 'Gute Reisezeit',
        noServiceTo: 'Kein Service für',
        from: 'von',
        buttonText: 'Suche nach besten {title} Kreuzfahrten'
      },
      shipsTo: 'Schiffe nach',
      shipsToShortDescription: 'Entdecken Sie unsere Schiffe im regulären Betrieb nach',
      topExperiencesTitle: 'Erstklassige Erlebnisse',
      seeAllFaqs: 'Alle FAQs ansehen',
      secondaryTitle: 'Unsere Reiseziele',
      activities: {
        footnote: '* Bitte beachten Sie, dass es sich hierbei um einen optionalen Ausflug handelt und zusätzliche Kosten anfallen.',
        seeAllActivities: 'See all activities for {name}',
        title: 'Activities in {name}'
      },
      map: {
        button: 'Lesen Sie unseren Reiseführer und unsere FAQs.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'Seereisen ansehen'
        }
      }
    },
    v2: {
      exploreDestination: 'Erkunden Sie {DESTINATION}',
      seeDestinationCruises: 'Siehe {DESTINATION} Kreuzfahrten',
      backToTop: 'Zurück zum Anfang',
      seeMoreArticles: 'Weitere Artikel ansehen',
      latestArticles: 'Neueste Artikel',
      learnMoreAboutHX: 'Erfahren Sie mehr über HX',
      destinationCruises: '{DESTINATION} Kreuzfahrten',
      chooseCruise: 'Wählen Sie eine Kreuzfahrt',
      videoUnavailable: 'Die Videowiedergabe ist nicht verfügbar',
      showResultCount: 'Zeigt {number} Kreuzfahrten',
      when: 'Wann',
      budget: 'Wie hoch ist Ihr Budget?',
      clear: 'Löschen',
      clearAll: 'alle löschen',
      search: 'Suchen',
      min: 'Min',
      max: 'Max',
      multipleOffers: 'Mehrere Angebote',
      numberDeparturesBetweenDates: '{number} Abflüge zwischen {date1} und {date2}',
      fromPrice: 'Ab {price}',
      addDate: 'Datum hinzufügen',
      addPrice: 'Preis hinzufügen',
      updateResults: 'Ergebnisse aktualisieren',
      imageDescriptionOfMap: 'Eine Karte der Zielroute',
      arialabel: {
        offersThatApplyToThisTag: 'Angebote, die für diese Kreuzfahrt gelten',
        previousYear: 'Vorheriges Jahr',
        nextYear: 'Nächstes Jahr',
        searchCruises: 'Kreuzfahrten suchen'
      },
      ourOtherDestinationsTitle: 'Unsere anderen Reiseziele'
    }
  },
  'gsw-CH': {
    destination: {
      title: 'Entdecken Sie die Welt mit uns',
      description: 'Erfahren Sie mehr über die spektakulären Reiseziele und besonderen Erlebnisse, die eine Expeditions-Seereise mit uns unvergesslich machen',
      destinationsButton: 'Weiter zum Reiseziel',
      storiesTitle: 'Geschichten von',
      associatedVoyagesTitle: 'Seereisen nach',
      highlights: {
        reasonsTo: 'Gründe für'
      },
      seasonality: {
        title: 'Die beste Reisezeit',
        bestTime: 'Beste Reisezeit',
        goodTime: 'Gute Reisezeit',
        noServiceTo: 'Kein Service für',
        from: 'von',
        buttonText: 'Suche nach besten {title} Kreuzfahrten'
      },
      shipsTo: 'Schiffe nach',
      shipsToShortDescription: 'Entdecken Sie unsere Schiffe im regulären Betrieb nach',
      topExperiencesTitle: 'Erstklassige Erlebnisse',
      seeAllFaqs: 'Alle FAQs ansehen',
      secondaryTitle: 'Unsere Reiseziele',
      activities: {
        footnote: '* Bitte beachten Sie, dass es sich hierbei um einen optionalen Ausflug handelt und zusätzliche Kosten anfallen.',
        seeAllActivities: 'See all activities for {name}',
        title: 'Activities in {name}'
      },
      map: {
        button: 'Lesen Sie unseren Reiseführer und unsere FAQs.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'Seereisen ansehen'
        }
      }
    },
    v2: {
      exploreDestination: 'Erkunden Sie {DESTINATION}',
      seeDestinationCruises: 'Siehe {DESTINATION} Kreuzfahrten',
      backToTop: 'Zurück zum Anfang',
      seeMoreArticles: 'Weitere Artikel ansehen',
      latestArticles: 'Neueste Artikel',
      learnMoreAboutHX: 'Erfahren Sie mehr über HX',
      destinationCruises: '{DESTINATION} Kreuzfahrten',
      chooseCruise: 'Wählen Sie eine Kreuzfahrt',
      videoUnavailable: 'Die Videowiedergabe ist nicht verfügbar',
      showResultCount: 'Zeigt {number} Kreuzfahrten',
      when: 'Wann',
      budget: 'Wie hoch ist Ihr Budget?',
      clear: 'Löschen',
      clearAll: 'alle löschen',
      search: 'Suchen',
      min: 'Min',
      max: 'Max',
      multipleOffers: 'Mehrere Angebote',
      numberDeparturesBetweenDates: '{number} Abflüge zwischen {date1} und {date2}',
      fromPrice: 'Ab {price}',
      addDate: 'Datum hinzufügen',
      addPrice: 'Preis hinzufügen',
      updateResults: 'Ergebnisse aktualisieren',
      imageDescriptionOfMap: 'Eine Karte der Zielroute',
      arialabel: {
        offersThatApplyToThisTag: 'Angebote, die für diese Kreuzfahrt gelten',
        previousYear: 'Vorheriges Jahr',
        nextYear: 'Nächstes Jahr',
        searchCruises: 'Kreuzfahrten suchen'
      },
      ourOtherDestinationsTitle: 'Unsere anderen Reiseziele'
    }
  },
  'fr-FR': {
    destination: {
      title: 'Explorez le monde avec nous',
      description: 'Apprenez-en davantage sur les destinations et les sites spectaculaires que vous pouvez explorer lors de l’une de nos croisières d’expédition',
      destinationsButton: 'Explorer la destination',
      storiesTitle: 'Articles sur',
      associatedVoyagesTitle: 'Croisières vers',
      highlights: {
        reasonsTo: 'Raisons de'
      },
      seasonality: {
        title: 'Quand partir',
        bestTime: 'Meilleure période',
        goodTime: 'Bonne période',
        noServiceTo: 'Aucun service à',
        from: 'depuis',
        buttonText: 'Recherche des meilleures {title} croisières'
      },
      shipsTo: 'Navires des',
      shipsToShortDescription: 'Explorez nos navires sur la destination',
      topExperiencesTitle: 'Meilleures expériences',
      seeAllFaqs: 'Voir toutes les FAQ',
      secondaryTitle: 'Nos destinations',
      activities: {
        footnote: '* Veuillez noter qu’il s’agit d’activités optionnelles moyennant un supplément.',
        seeAllActivities: 'Voir toutes les activités pour {name}',
        title: 'Activités de {name}'
      },
      map: {
        button: 'Lisez notre guide de voyage et nos FAQ.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'Consulter les croisières'
        }
      }
    },
    v2: {
      exploreDestination: 'Explorer {DESTINATION}',
      seeDestinationCruises: 'Voir les croisières de {DESTINATION}',
      backToTop: 'Retour au début',
      seeMoreArticles: 'Voir plus d\'articles',
      latestArticles: 'Derniers articles',
      learnMoreAboutHX: 'En savoir plus sur HX',
      destinationCruises: 'Croisières {DESTINATION}',
      chooseCruise: 'Choisir une croisière',
      videoUnavailable: 'La lecture de la vidéo n\'est pas disponible',
      showResultCount: 'Afficher {number} croisières',
      when: 'Quand',
      budget: 'Quel est votre budget?',
      clear: 'Clair',
      clearAll: 'tout effacer',
      search: 'Recherche',
      min: 'Min',
      max: 'Max',
      multipleOffers: 'Offres multiples',
      numberDeparturesBetweenDates: '{number} de départs entre {date1} et {date2}',
      fromPrice: 'A partir de {price}',
      addDate: 'Ajouter une date',
      addPrice: 'Ajouter un prix',
      updateResults: 'Mise à jour des résultats',
      imageDescriptionOfMap: 'Carte de l\'itinéraire de destination',
      arialabel: {
        offersThatApplyToThisTag: 'Offres qui s\'appliquent à cette croisière',
        previousYear: 'Année précédente',
        nextYear: 'Année suivante',
        searchCruises: 'Rechercher des croisières'
      },
      ourOtherDestinationsTitle: 'Nos autres destinations'
    }
  },
  'da-DK': {
    destination: {
      title: 'Udforsk verden sammen med os',
      description: 'Læs mere om de spektakulære destinationer og attraktioner, du kan opleve på et ekspeditionskrydstogt med os.',
      destinationsButton: 'Gå til destination',
      storiesTitle: 'Historier fra',
      associatedVoyagesTitle: 'Krydstogter til',
      highlights: {
        reasonsTo: 'Årsager til'
      },
      seasonality: {
        title: 'Hvad kan du opleve, og hvornår',
        bestTime: 'Bedste tidspunkt',
        goodTime: 'Godt tidspunkt',
        noServiceTo: 'Ingen service til',
        from: 'fra',
        buttonText: 'Søg efter bedste {title} krydstogter'
      },
      shipsTo: 'Skibe til',
      shipsToShortDescription: 'Udforsk vores skibe, som regelmæssigt sejler til',
      topExperiencesTitle: 'Uovertrufne oplevelser',
      seeAllFaqs: 'Se alle ofte stillede spørgsmål',
      secondaryTitle: 'Vores destinationer',
      activities: {
        footnote: '* De valgfrie aktiviteter skal der muligvis betales ekstra for.',
        seeAllActivities: 'Se alle aktiviteter for {name}',
        title: 'Aktiviteter i {name}'
      },
      map: {
        button: 'Læs vores rejseguide og ofte stillede spørgsmål.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'Vis krydstogter'
        }
      }
    },
    v2: {
      exploreDestination: 'Udforsk {DESTINATION}',
      seeDestinationCruises: 'Se {DESTINATION} krydstogter',
      backToTop: 'Tilbage til toppen',
      seeMoreArticles: 'Se flere artikler',
      latestArticles: 'Seneste artikler',
      learnMoreAboutHX: 'Få mere at vide om HX',
      destinationCruises: '{DESTINATION} krydstogter',
      chooseCruise: 'Vælg et krydstogt',
      videoUnavailable: 'Videoafspilning er ikke tilgængelig',
      showResultCount: 'Viser {number} krydstogter\'',
      when: 'Hvornår',
      budget: 'Hvad er dit budget?',
      clear: 'Ryd',
      clearAll: 'ryd alle',
      search: 'Søg',
      min: 'Min',
      max: 'Max',
      multipleOffers: 'Flere tilbud',
      numberDeparturesBetweenDates: '{number} afgange mellem {date1} og {date2}',
      fromPrice: 'Fra {price}\'',
      addDate: 'Tilføj dato',
      addPrice: 'Tilføj pris',
      updateResults: 'Opdater resultater',
      imageDescriptionOfMap: 'Et kort over destinationsruten',
      arialabel: {
        offersThatApplyToThisTag: 'Tilbud, der gælder for dette krydstogt',
        previousYear: 'Forrige år',
        nextYear: 'Næste år',
        searchCruises: 'Søg efter krydstogter'
      },
      ourOtherDestinationsTitle: 'Vores andre destinationer'
    }
  },
  'sv-SE': {
    destination: {
      title: 'Upptäck världen med oss',
      description: 'Läs mer om de oförglömliga resmål och sevärdheter som du kan uppleva på en expeditionskryssning med oss',
      destinationsButton: 'Gå till resmål',
      storiesTitle: 'Berättelser från',
      associatedVoyagesTitle: 'Kryssningar till',
      highlights: {
        reasonsTo: 'Anledningar att'
      },
      seasonality: {
        title: 'Vad du kan uppleva och när',
        bestTime: 'Bästa tidpunkt',
        goodTime: 'Bra tid',
        noServiceTo: 'Inga resor till',
        from: 'från',
        buttonText: 'Sök efter bästa {title} kryssningar'
      },
      shipsTo: 'Fartyg till',
      shipsToShortDescription: 'Utforska de fartyg som används under våra',
      topExperiencesTitle: 'Populära upplevelser',
      seeAllFaqs: 'Visa alla vanliga frågor',
      secondaryTitle: 'Våra destinationer',
      activities: {
        footnote: '*Observera att detta är tillvalsaktiviteter, så ytterligare kostnader kan tillkomma.',
        seeAllActivities: 'Se alla aktiviteter i {name}',
        title: 'Aktiviteter i {name}'
      },
      map: {
        button: 'Läs vår reseguide och vanliga frågor.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'Visa kryssningar'
        }
      }
    },
    v2: {
      exploreDestination: 'Utforska {DESTINATION}',
      seeDestinationCruises: 'Se {DESTINATION} kryssningar',
      backToTop: 'Tillbaka till toppen',
      seeMoreArticles: 'Se fler artiklar',
      latestArticles: 'Senaste artiklarna',
      learnMoreAboutHX: 'Lär dig mer om HX',
      destinationCruises: '{DESTINATION} kryssningar',
      chooseCruise: 'Välj en kryssning',
      videoUnavailable: 'Videouppspelning är inte tillgänglig',
      showResultCount: 'Visar {number} kryssningar',
      when: 'När',
      budget: 'Vad är din budget?',
      clear: 'Rensa',
      clearAll: 'rensa alla',
      search: 'Sök',
      min: 'Min',
      max: 'Max',
      multipleOffers: 'Flera erbjudanden',
      numberDeparturesBetweenDates: '{number} avgångar mellan {date1} och {date2}”',
      fromPrice: 'Från {price}',
      addDate: 'Lägg till datum',
      addPrice: 'Lägg till pris',
      updateResults: 'Uppdatera resultat',
      imageDescriptionOfMap: 'En karta över rutten till destinationen',
      arialabel: {
        offersThatApplyToThisTag: 'Erbjudanden som gäller för denna kryssning',
        previousYear: 'Föregående år',
        nextYear: 'Nästa år',
        searchCruises: 'Sök kryssningar'
      },
      ourOtherDestinationsTitle: 'Våra övriga destinationer'
    }
  },
  'nb-NO': {
    destination: {
      title: 'Utforsk verden med oss',
      description: 'Lær mer om de spektakulære destinasjonene og høydepunktene du kan oppleveve på ekspedisjonscruise med oss. ',
      destinationsButton: 'Gå til destinasjonen',
      storiesTitle: 'Historier fra',
      associatedVoyagesTitle: 'Cruise til',
      highlights: {
        reasonsTo: 'Grunner til å'
      },
      seasonality: {
        title: 'Hva du kan oppleve, og når',
        bestTime: 'Beste tidspunkt',
        goodTime: 'Godt tidspunkt',
        noServiceTo: 'Ingen tilbud til',
        from: 'fra',
        buttonText: 'Søk etter beste {title} cruise'
      },
      shipsTo: 'Skip til',
      shipsToShortDescription: 'Utforsk skipene våre som ofte reiser til',
      topExperiencesTitle: 'Opplevelser du må få med deg',
      seeAllFaqs: 'Se alle ofte stilte spørsmål',
      secondaryTitle: 'Destinasjonene våre',
      activities: {
        footnote: '* Merk at dette er valgfrie aktiviteter som kan koste ekstra.',
        seeAllActivities: 'Se alle aktiviteter for {name}',
        title: 'Aktiviteter i {name}'
      },
      map: {
        button: 'Les vår reiseguide og ofte stilte spørsmål.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'Se cruise'
        }
      }
    },
    v2: {
      exploreDestination: 'Utforsk {DESTINATION}',
      seeDestinationCruises: 'Se {DESTINATION} cruise',
      backToTop: 'Tilbake til toppen',
      seeMoreArticles: 'Se flere artikler',
      latestArticles: 'Siste artikler',
      learnMoreAboutHX: 'Lær mer om HX',
      destinationCruises: '{DESTINATION} cruise',
      chooseCruise: 'Velg et cruise',
      videoUnavailable: 'Videoavspilling er ikke tilgjengelig',
      showResultCount: 'Viser {number} cruise',
      when: 'Når',
      budget: 'Hva er budsjettet ditt?',
      clear: 'Fjern',
      clearAll: 'fjerne alt',
      search: 'Søk',
      min: 'Min',
      max: 'Max',
      multipleOffers: 'Flere tilbud',
      numberDeparturesBetweenDates: '{number} avganger mellom {date1} og {date2}',
      fromPrice: 'Fra {price}',
      addDate: 'Legg til dato',
      addPrice: 'Legg til pris',
      updateResults: 'Oppdater resultater',
      imageDescriptionOfMap: 'Et kart over destinasjonsruten',
      arialabel: {
        offersThatApplyToThisTag: 'Tilbud som gjelder for dette cruiset',
        previousYear: 'Forrige år',
        nextYear: 'Neste år',
        searchCruises: 'Søk etter cruise'
      },
      ourOtherDestinationsTitle: 'Våre andre destinasjoner'
    }
  },
  'en': {
    destination: {
      title: 'Explore the world with us',
      description: 'Learn more about the spectacular destinations and attractions that you can experience on an expedition cruise with us',
      destinationsButton: 'Go to destination',
      storiesTitle: 'Stories from',
      associatedVoyagesTitle: 'Cruises to',
      highlights: {
        reasonsTo: 'Reasons to'
      },
      seasonality: {
        title: 'When to see',
        bestTime: 'Best time',
        goodTime: 'Good time',
        noServiceTo: 'No service to',
        from: 'from',
        buttonText: 'Search for best {title} cruises'
      },
      shipsTo: 'Ships to',
      shipsToShortDescription: 'Explore our ships that regularly sail to',
      topExperiencesTitle: 'Top experiences',
      seeAllFaqs: 'See all FAQs',
      secondaryTitle: 'Our destinations',
      activities: {
        footnote: '* Please note that these are optional activities; an additional cost may apply.',
        seeAllActivities: 'See all activities for {name}',
        title: 'Activities in {name}'
      },
      map: {
        button: 'Read our travel guide and FAQs.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'View cruises'
        }
      }
    },
    v2: {
      exploreDestination: 'Explore {DESTINATION}',
      seeDestinationCruises: 'See {DESTINATION} cruises',
      backToTop: 'Back to top',
      seeMoreArticles: 'See more articles',
      latestArticles: 'Latest Articles',
      learnMoreAboutHX: 'Learn more ',
      destinationCruises: '{DESTINATION} cruises',
      chooseCruise: 'Choose a cruise',
      videoUnavailable: 'Video playback is not available',
      showResultCount: 'Showing {number} cruises',
      when: 'When',
      budget: 'What\'s your budget?',
      clear: 'Clear',
      clearAll: 'Clear all',
      search: 'Search',
      min: 'Min',
      max: 'Max',
      multipleOffers: 'Multiple Offers',
      numberDeparturesBetweenDates: '{number} departures between {date1} and {date2}',
      fromPrice: 'From {price}',
      addDate: 'Add date',
      addPrice: 'Add price',
      updateResults: 'Update results',
      imageDescriptionOfMap: 'A map of the destination route',
      arialabel: {
        offersThatApplyToThisTag: 'Offers that apply to this cruise',
        previousYear: 'Previous year',
        nextYear: 'Next year',
        searchCruises: 'Search cruises'
      },
      ourOtherDestinationsTitle: 'Our other destinations'
    }
  }
}

export type TDestination = {
  destination: {
    title: string;
    description: string;
    destinationsButton: string;
    storiesTitle: string;
    associatedVoyagesTitle: string;
    highlights: {
      reasonsTo: string;
    };
    seasonality: {
      title: string;
      bestTime: string;
      goodTime: string;
      noServiceTo: string;
      from: string;
      buttonText: string;
    };
    shipsTo: string;
    shipsToShortDescription: string;
    topExperiencesTitle: string;
    seeAllFaqs: string;
    secondaryTitle: string;
    activities: {
      footnote: string;
      seeAllActivities: string;
      title: string;
    };
    map: {
      button: string;
    };
    hero: {
      anchorLink: {
        viewCruises: string;
      };
    };
  };
  v2: {
    exploreDestination: string;
    seeDestinationCruises: string;
    backToTop: string;
    seeMoreArticles: string;
    latestArticles: string;
    learnMoreAboutHX: string;
    destinationCruises: string;
    chooseCruise: string;
    videoUnavailable: string;
    showResultCount: string;
    when: string;
    budget: string;
    clear: string;
    clearAll: string;
    search: string;
    min: string;
    max: string;
    multipleOffers: string;
    numberDeparturesBetweenDates: string;
    fromPrice: string;
    addDate: string;
    addPrice: string;
    updateResults: string;
    imageDescriptionOfMap: string;
    arialabel: {
      offersThatApplyToThisTag: string;
      previousYear: string;
      nextYear: string;
      searchCruises: string;
    };
    ourOtherDestinationsTitle: string;
  };
}
