import Head from 'next/head';

import { buildHrefLang } from '@src/utils/buildHrefLang';
import { ISlugAndLocale } from '@content/requests/getAllSlugLocales';

const isLocaleArray = (values: any[]): values is TLocale[] =>
  values.every((item) => typeof item === 'string');

export const HrefLangs = ({
  entries,
  mappers,
  page,
  query
}: {
  entries: TLocale[] | ISlugAndLocale[];
  /** Array of mapper functions */
  mappers: ((e: TLocale) => string)[];
  /** Number of pagination if applicable */
  page?: string | string[];
  query?: string | string[];
}) => {
  let hrefLangs = null;

  if (isLocaleArray(entries)) {
    hrefLangs = entries.map((validLocale: TLocale) => (
      <link
        rel="alternate"
        key={validLocale}
        hrefLang={validLocale === 'en' ? 'x-default' : validLocale}
        href={buildHrefLang({
          locale: validLocale,
          mappers,
          page,
          query
        })}
      />
    ));
  } else {
    hrefLangs = entries
      ?.filter((alt) => alt.slug?.length)
      .map((alternateLink) => (
        <link
          rel="alternate"
          key={alternateLink.locale}
          hrefLang={
            alternateLink.locale === 'en' ? 'x-default' : alternateLink.locale
          }
          href={buildHrefLang({
            locale: alternateLink.locale,
            mappers,
            query: alternateLink.slug
          })}
        />
      ));
  }

  return <Head>{hrefLangs}</Head>;
};
