import { getSharedEnvironmentVariables } from './environmentVariables';
import pickFirst from './pickFirst';

const sharedEnvironmentVariables = getSharedEnvironmentVariables();

const checkSlashes = (value: string) => {
  if (!value) {
    return value;
  }
  return value.endsWith('/') ? value : `${value}/`;
};

export const buildHrefLang = ({
  locale,
  mappers,
  query,
  page
}: {
  locale: TLocale;
  /** Array of mapper functions */
  mappers: ((e: TLocale) => string)[];
  query?: string | string[];
  /** Number of pagination if applicable */
  page?: string | string[];
}) => {
  const mappedUrl = mappers
    .map((mapFunction: (e: TLocale) => string) => mapFunction(locale))
    .toString()
    .replace(',', '/');
  return `${
    sharedEnvironmentVariables.WEBSITE_URL ?? '/'
  }${locale}/${checkSlashes(mappedUrl)}${
    query ? `${checkSlashes(pickFirst(query))}` : ''
  }${page ? `?page=${pickFirst(page)}` : ''}`;
};
